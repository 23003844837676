import { Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import { NoTrialCompleted, styles } from '.'
import { ACADEMIC, HOBBIES, LANGUAGES, TRIAL, REFERENCES } from './Constants'
import LineBreak from './LineBreak'

const AcademicTemplate = ({ data }) => {
  return (
    <View>
      {data.map((item, index) => (
        <View
          style={{
            ...styles.line,
          }}
          key={index}
        >
          <Text style={{ ...styles.heading, fontWeight: 'normal' }}>
            {item.startingYear} - {item.endingYear}
          </Text>
          <Text style={styles.heading_value}>
            {item.schoolName}, {item.place}
          </Text>
        </View>
      ))}
    </View>
  )
}

const LanguageTemplate = ({ data }) => {
  return (
    <View>
      {data.map((item, index) => (
        <View
          style={{
            ...styles.line,
          }}
          key={index}
        >
          <Text style={{ ...styles.heading, fontWeight: 'normal' }}>
            {item?.language}
          </Text>
          <Text style={styles.heading_value}>{item?.proficiency}</Text>
        </View>
      ))}
    </View>
  )
}

const TrialTemplate = ({ data }) => {
  return (
    <View>
      {data !== NoTrialCompleted ? (
        data.map((item, index) => (
          <View
            style={{
              ...styles.line,
            }}
            key={index}
          >
            <Text style={{ ...styles.heading, fontWeight: 'normal' }}>
              {`${moment(item.date?.from).format('DD.MM')}. - ${moment(
                item.date?.to
              ).format('DD.MM.YYYY')}`}
            </Text>
            <Text style={styles.heading_value}>
              {[item.job, item.companyName, item.place]
                .filter(Boolean)
                .join(', ')}
            </Text>
          </View>
        ))
      ) : (
        <Text style={styles.heading_value}>{data}</Text>
      )}
    </View>
  )
}

const HobbyTemplate = ({ data }) => {
  return (
    <View>
      {data.map((item, index) => (
        <View
          style={{
            ...styles.line,
          }}
          key={index}
        >
          <Text style={{ ...styles.heading, fontWeight: 'normal' }}>
            {item.title}
          </Text>
          <Text style={styles.heading_value}>{item.description}</Text>
        </View>
      ))}
    </View>
  )
}

const ReferenceTemplate = ({ data, trial }) => {
  return (
    <View
      style={{
        display: 'flex',
        gap: 6,
        flexDirection:
          typeof trial !== 'string' && trial.length >= 4 ? 'row' : 'column',
        width: '75%',
        justifyContent: 'space-between',
      }}
    >
      {data.map((item, index) => (
        <View style={{ ...styles.line, flexDirection: 'column' }} key={index}>
          <Text style={styles.heading_value}>
            {`${item.firstName} ${item.lastName} ${
              item.designation ? '(' + item.designation + ')' : ''
            }`}
          </Text>
          {item.contactNumber && (
            <Text style={styles.heading_value}>
              {`Telefon ${item.contactNumber}`}
            </Text>
          )}
          {item.email && <Text style={styles.heading_value}>{item.email}</Text>}
        </View>
      ))}
    </View>
  )
}

const CVAcademicHistory = ({ data, role = ACADEMIC, trial }) => {
  if (!data.length) return null
  const title =
    role === ACADEMIC
      ? 'Schulbildung'
      : role === LANGUAGES
      ? 'Sprachen'
      : role === HOBBIES
      ? 'Hobbys'
      : role === TRIAL
      ? 'Schnupperlehren'
      : role === REFERENCES
      ? 'Referenzen'
      : ''

  return (
    <View>
      <LineBreak />
      <Text style={{ ...styles.heading, marginBottom: 3 }}>{title}</Text>
      {role === ACADEMIC ? (
        <AcademicTemplate data={data} />
      ) : role === LANGUAGES ? (
        <LanguageTemplate data={data} />
      ) : role === HOBBIES ? (
        <HobbyTemplate data={data} />
      ) : role === TRIAL ? (
        <TrialTemplate data={data} />
      ) : role === REFERENCES ? (
        <ReferenceTemplate data={data} trial={trial} />
      ) : null}
    </View>
  )
}
export default CVAcademicHistory
